import React, { createRef } from "react"
import { navigate } from "gatsby-link"

import Layout from "../../components/Layout"

import Sidenav from "../../components/dashboard/sidenav"
import DashboardBody from "../../components/dashboard/dashboardbody"

import "../../components/Styles/Custom.scss"
import "../../components/Styles/Dashboard.scss"
import axios from "axios"

import { uploadFile } from "react-s3"

import imageCompression from "browser-image-compression"

const config = {
  bucketName: "globelynx-booking-portal-experts-compressed",
  dirName: "public",
  region: "eu-west-1",
  accessKeyId: "AKIA6OWQUQ2FHYJBOXMC",
  secretAccessKey: "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",
}

let buildURL =
  "https://api.cloudflare.com/client/v4/pages/webhooks/deploy_hooks/24ac5919-cb28-4e95-904e-b8ff028eef2f"

let apiURL = "https://api.globelynx.com/api/"

export default class PageEditor extends React.Component {
  constructor(props) {
    super(props)

    if (typeof document !== "undefined" || typeof window !== "undefined") {
      var now = new Date()
      var timestamp_now = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
      // var timestamp_now = Math.floor(Date.now() / 1000);
      let token = localStorage.getItem("username")
      token = JSON.parse(token)

      if (
        localStorage.getItem("username") === null ||
        timestamp_now >= token.expires
      ) {
        navigate("/login")
      } else {
        this.ReactQuill = require("react-quill")
      }
    }

    this.state = {
      titleValue: "",
      author: "",
      videoembed: "",
      editorValue: "",
      allPages: [],
      editedid: "",
      setIsIE: false,
      isLoading: false,
      headerImage: "",
      imagePreviewUrl: "",
      headerFile: "",
      subText: "",

      modalShow: false,
      idtobedelete: "",
    }

    this.publishPage = this.publishPage.bind(this)
    this.oChangeEditor = this.oChangeEditor.bind(this)
    this.getAllPages = this.getAllPages.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.editPages = this.editPages.bind(this)
    this.deletePage = this.deletePage.bind(this)

    this.imageHandler = this.imageHandler.bind(this)

    this.headerImage = this.headerImage.bind(this)

    this.reactQuillRef = ""

    this.modules = {
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
          ["clean"],
        ],
        handlers: {
          image: this.imageHandler,
        },
      },
    }

    this.formats = [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
    ]
  }

  headerImage(e) {
    var self = this
    e.preventDefault()
    let reader = new FileReader()

    const imageFile = e.target.files[0]
    reader.readAsDataURL(imageFile)
    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
        headerFile: imageFile,
      })
    }

    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    }
    imageCompression(imageFile, options)
      .then(function(compressedFile) {
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ) // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ) // smaller than maxSizeMB

        uploadFile(compressedFile, config)
          .then((data) => {
            self.setState({
              headerImage: data.location,
            })
          })
          .catch((err) => console.log(err))
      })
      .catch(function(error) {
        console.log(error.message)
      })
  }

  deletePage() {
    this.setState({
      modalShow: false,
    })

    var id = this.state.idtobedelete
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    }
    fetch(apiURL + "pages/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        alert("Item has been deleted")

        axios
          .post(buildURL)
          .then(function(response) {
            // handle success
            alert("Building site.")
          })
          .catch(function(error) {
            // handle error
            // alert("Error Build");
            console.log(error)
          })
          .then(function() {
            window.location.reload()
          })
      })
      .catch((error) => alert("Something went wrong"))
  }

  editPages(pos) {
    this.setState({
      titleValue: decodeURI(this.state.allPages[pos].pagesName),
      author: decodeURI(this.state.allPages[pos].author),
      videoembed: this.state.allPages[pos].videoembed,
      editorValue: this.state.allPages[pos].pagesContent,
      editedid: this.state.allPages[pos]._id,
      subText: decodeURI(this.state.allPages[pos].subText),
      headerImage: this.state.allPages[pos].headerImage,
      imagePreviewUrl: this.state.allPages[pos].headerImage,
    })
  }

  handleChange(e, name) {
    this.setState(
      {
        [name]: e,
      },
      () => {
        console.log("this.state :>> ", this.state)
      }
    )
  }

  componentDidMount() {
    this.getAllPages()
  }

  urlExists(url, callback) {
    fetch(url, { method: "head" }).then(function(status) {
      callback(status.ok)
    })
  }

  getAllPages() {
    var self = this

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch(apiURL + "pages", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        // this.setState({
        //   allPages: [...result],
        // });
        var path = []
        result.map((val, key) => {
          var str = decodeURI(val.pagesName)
          str = str.replace(/[^a-z\d\s]+/gi, " ")
          str = str.replace(/\s+/g, " ").trim()
          str = str.replace(/\s+/g, "-").toLowerCase()

          path.push({
            ...val,
            pathname: str,
          })
          self.setState({
            allPages: path,
          })
        })
      })
      .catch((error) => console.log("error", error))
  }

  publishPage() {
    let self = this

    if (this.state.editedid === "") {
      this.setState({
        isLoading: true,
      })

      var myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/json")

      var headerimg = this.state.headerImage
      if (headerimg == "") {
        headerimg = "none"
      }

      var raw = JSON.stringify({
        pagesName: encodeURI(this.state.titleValue),
        pagesContent: this.state.editorValue,
        author: encodeURI(this.state.author),
        subText: encodeURI(this.state.subText),
        headerImage: headerimg,
        videoembed:
          this.state.videoembed == "" ? "no url" : this.state.videoembed,
      })

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      }

      fetch(apiURL + "pages", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.isActive == undefined) {
            alert(result.message)
          } else {
            alert("New page has been added.")
            axios
              .post(buildURL)
              .then(function(response) {
                // handle success
                alert("Building site.")
              })
              .catch(function(error) {
                // handle error
                //                alert("Error Build");
              })
              .then(function() {
                // always executed
                self.setState({
                  isLoading: false,
                })
                window.location.reload()
              })
          }
        })
        .catch((error) => {
          alert(error)
          self.setState({
            isLoading: false,
          })
        })
    } else {
      var myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/json")

      var headerimg = this.state.headerImage
      if (headerimg == "") {
        headerimg = "none"
      }

      var raw = JSON.stringify({
        pagesName: encodeURI(this.state.titleValue),
        pagesContent: this.state.editorValue,
        author: encodeURI(this.state.author),
        subText: encodeURI(this.state.subText),
        headerImage: headerimg,
        videoembed:
          this.state.videoembed == "" ? "no url" : this.state.videoembed,
      })

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      }

      fetch(apiURL + "pages/" + this.state.editedid, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          alert("Page is updated")
          axios
            .post(buildURL)
            .then(function(response) {
              // handle success
              alert("Building site.")
            })
            .catch(function(error) {
              // handle error
              //              alert("Error Build");
            })
            .then(function() {
              // always executed
              self.setState({
                isLoading: false,
              })
              window.location.reload()
            })
        })
        .catch((error) => console.log("error", error))
    }
  }

  oChangeEditor(e) {
    this.setState({
      editorValue: e,
    })
  }

  imageHandler() {
    if (typeof document !== "undefined" || typeof window !== "undefined") {
      var self = this
      const input = document.createElement("input")

      input.setAttribute("type", "file")
      input.setAttribute("accept", "image/*")
      input.click()

      input.onchange = async function() {
        const imageFile = input.files[0]
        console.log("User trying to uplaod this:", imageFile)

        let reader = new FileReader()
        reader.readAsDataURL(imageFile)

        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        }
        imageCompression(imageFile, options)
          .then(function(compressedFile) {
            console.log(
              "compressedFile instanceof Blob",
              compressedFile instanceof Blob
            ) // true
            console.log(
              `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
            ) // smaller than maxSizeMB

            uploadFile(compressedFile, config)
              .then((data) => {
                console.log(self.reactQuillRef)
                const range = self.reactQuillRef.getEditor().getSelection()
                // const link = `${ROOT_URL}/file/${id}`;

                // self part the image is inserted
                // by 'image' option below, you just have to put src(link) of img here.
                self.reactQuillRef
                  .getEditor()
                  .insertEmbed(range.index, "image", data.location)
              })
              .catch((err) => console.log(err))
          })
          .catch(function(error) {
            console.log(error.message)
          })
      }.bind(this) // react thing
    }
  }

  uploadFile() {
    alert("-")
    // alert('-')
  }

  render() {
    let { imagePreviewUrl } = this.state
    let $imagePreview = null
    if (imagePreviewUrl) {
      $imagePreview = (
        <img
          src={imagePreviewUrl}
          style={{ height: "150px", width: "150px" }}
        />
      )
    }

    const ReactQuill = this.ReactQuill

    if (typeof window !== "undefined" && ReactQuill) {
      return (
        <Layout location="dashboard">
          <Sidenav />
          {/* <DashboardBody /> */}

          <div className="main">
            <div className="container-fluid">
              <div className="row">
                {/* <input type="file" id="getFile" onClick={alert()} /> */}

                <div className="three columns">
                  <table className="u-full-width">
                    <thead>
                      <tr>
                        <th style={{ justifyContent: "center" }}>Title</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.allPages.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td style={{ padding: 10 }}>
                              {decodeURI(val.pagesName)}
                            </td>

                            <td style={{ width: 90 }}>
                              <a href={val.pathname} target="_blank">
                                <i
                                  style={{ color: "#004085", paddingRight: 8 }}
                                  className="fa fa-globe"
                                />
                              </a>

                              <i
                                style={{
                                  color: "#00bcd4",
                                  paddingRight: 8,
                                }}
                                onClick={(e) => this.editPages(key)}
                                className="fa fa-edit"
                              />

                              <i
                                style={{ color: "#d40000b8", paddingRight: 8 }}
                                onClick={(e) => {
                                  this.setState({
                                    modalShow: true,
                                    idtobedelete: val._id,
                                  })
                                }}
                                className="fa fa-trash"
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="nine columns">
                  <div className="row">
                    <div className="twelve columns">
                      <button onClick={this.publishPage}>
                        Publish{" "}
                        {this.state.isLoading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                    <div className="twelve columns">
                      <label>Title</label>
                      <input
                        className="u-full-width"
                        type="text"
                        value={this.state.titleValue}
                        onChange={(e) =>
                          this.handleChange(e.target.value, "titleValue")
                        }
                      />
                    </div>

                    <div className="twelve columns">
                      <label>
                        Meta Description{" "}
                        <span
                          style={{
                            color: "#666",
                            fontSize: "14px",
                            fontStyle: "italic",
                          }}
                        >
                          (limit 160 chars.)
                        </span>
                      </label>
                      <input
                        maxLength={160}
                        className="u-full-width"
                        type="text"
                        value={this.state.subText}
                        onChange={(e) =>
                          this.handleChange(e.target.value, "subText")
                        }
                      />
                    </div>

                    <div className="twelve columns">
                      <label>Author</label>
                      <input
                        className="u-full-width"
                        type="text"
                        value={this.state.author}
                        onChange={(e) =>
                          this.handleChange(e.target.value, "author")
                        }
                      />
                    </div>

                    <div className="twelve columns">
                      <label>Embed Video</label>
                      <input
                        className="u-full-width"
                        type="text"
                        value={
                          this.state.videoembed == "no url"
                            ? ""
                            : this.state.videoembed
                        }
                        onChange={(e) =>
                          this.handleChange(e.target.value, "videoembed")
                        }
                      />
                    </div>

                    <div
                      className="twelve columns"
                      style={{ textAlign: "center" }}
                    >
                      <label id="InputFileUploaderbb">
                        {" "}
                        Add Header Image
                        <input type="file" onChange={this.headerImage} />
                      </label>

                      {this.state.editedid == "" ? (
                        $imagePreview
                      ) : (
                        <img
                          id="previewprofile"
                          style={{ height: "150px", width: "150px" }}
                          src={this.state.imagePreviewUrl}
                          onError={(e) => {
                            e.target.onerror = null
                            e.target.src =
                              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXz9PTa29zx8vLq6uvk5eXg4eLc3t7t7u/Y2dr19vbn6Oni4+Tj5eXp6eru7/Da3NwT/lyMAAADW0lEQVR4nO3a646cIBiAYTyh4K5z/3dbBVQ8jm63GT77Pn+aJrXxjQ4IqhQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACALdNT3afP4h8Z2vLatjpr8k+fy2/zF64oq6zJnMJ8+pR+TWizlQ5tnn1E4XxTbrXCC4e2/sJ9VTttnhZbONyUZvjB6azZb6vK1/DHp0/0J4a2MFLulTWZbm2d9//I3bWy5gvf9t0e3JR9W1UWuR90+n/dShpMp1lgbzQJN2XRhR/meIyVMpie35SZvykXbeG42pWnX2iK6uAH1+jK1t14U27l7jABhXbvurmb8rAtyIQMpvW1m3JLzmA63aOVvXDhZmIGU+Pq7J22cKC7v0sBhW766+6fqOmkDKblj+e1RsZQY4rhPMdFgqleR5P+BfqzKUcWN5s5XkfILVzMa48s9PNa2HF5ZqGLqk30l6cVxouEkJvf1qVcGA+mP54d84QLw8ktrqEKGxlXJV4YD6ZTYVfe0D+cJl0YLxLGQlMcLIj3NGXqhdEiYS68M4YmXxgtEraFpS0Pd2/EFEY7LuvCyi2GS+GF8Y7LqlBfew5IvtAPpu65bVU4ruC783En+cJ5ElwXjrswRvg1jBbBlwsrVWhBhfNz26pwfFqtN4FGGSWnMFoErwpfyo8/6/nCpZhcTqGaBtP1bKG7Ydd/M5T61eR0adMvdD8z99nBdsbX5XaqyMc9Dyum0EUMU8OVp7ZmXlz5MUpC4TSYXimso9Wjf6YVUDgNphcKl/v47oD0C/1gqi8VbvaOtYjCaRH8tnDznmKYMwQUugmv6d4X7nxAY7rkV8AqWgS/Kdx9D2MKCYXjIvi8sDo4Ok99JypaBO8UlvPkf/I/pF7oTzDbKRwe2/LKLw/zk23U5AvHRfC60Pqvg+p3gekXTmWLQj0+nxnVLh5lttIv/ApXLC60KvoE6vQKSij89oNptCOs3zQtJV84PrfNhTff7Kdf6IcaM29K3Xz9JKDQvwmOtt3uSb/Qp4XPL59ZGO/cP7OweHrhYuO+u/UC2L8FTr9QRYXtnRfAXpt+odG6tcV2a/SOtAvDpwlPLnT+h8IbXyjILCz+yqdP/4r788TyyyEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAzfwCESiaORYwGQwAAAABJRU5ErkJggg=="
                          }}
                        />
                      )}
                    </div>

                    <div
                      className="twelve columns"
                      style={{
                        paddingBottom: 150,
                      }}
                    >
                      {
                        <ReactQuill
                          theme="snow"
                          ref={(el) => {
                            this.reactQuillRef = el
                          }}
                          value="<h1>test</h1>"
                          value={this.state.editorValue}
                          onChange={(e) => this.oChangeEditor(e)}
                          modules={this.modules}
                          formats={this.formats}
                          style={{
                            height: "100vh",
                          }}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="userModalContainer"
            className="modal"
            style={{
              zIndex: "1111",
              display: this.state.modalShow ? "block" : "none",
            }}
          >
            <div
              className="modal-content has-text-centered"
              style={{
                height: "unset",
                position: "relative",
                marginTop: "30px",
              }}
            >
              <span
                className="close"
                onClick={(e) =>
                  this.setState({ modalShow: false, idtobedelete: "" })
                }
              >
                &times;
              </span>
              <h2 className="has-text-centered">
                Are you sure you want to delete?
              </h2>

              <div className="field">
                <i
                  style={{
                    fontSize: 15,
                    color: "#00bcd4",
                    paddingRight: 8,
                    paddingLeft: 10,
                  }}
                  className="fa fa-check"
                  onClick={this.deletePage}
                />

                <i
                  style={{ fontSize: 15, color: "#d40000b8", paddingRight: 8 }}
                  className="fa fa-times"
                  onClick={(e) =>
                    this.setState({ modalShow: false, idtobedelete: "" })
                  }
                />
              </div>
            </div>
          </div>
        </Layout>
      )
    } else {
      return ""
    }
  }
}
